// redirect to the user's build_path as required
export function maybeRedirectToBuildPath({ build_path }: { build_path: string }) {
  function stripCurrentStoryPathname(pathname: string) {
    const [storyPrefix] = RegExp('^/vl(nhs|axa|m|s|t)-[^/]*-(story|bug)').exec(pathname) || [];

    return storyPrefix ? pathname.substring(storyPrefix.length) : pathname;
  }

  if (!build_path) return '';

  const { pathname, hostname } = window.location;
  const currentBuildPath = pathname.split('/').find((s) => s.length > 0);

  if (hostname !== 'localhost' && currentBuildPath !== build_path) {
    // OK, I'm on the wrong build_path, time to redirect
    const url = new URL(window.location.href);

    url.pathname = `${build_path}${stripCurrentStoryPathname(url.pathname)}`;
    console.log('Redirecting to build_path (maybeRedirectToBuildPath):', url.toString());
    window.location.replace(url);

    return true;
  }

  return '';
}
